import { TRAFFIC_CONTROLLER } from './../../utils/authorites';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import {
  ADVERTISER_ADMIN, ADVERTISER_USER, COMPANY_ADMIN,
  COMPANY_USER, ROLE_ADMIN, ROLE_USER, MEDIA_HOUSE_ADMIN,
  MEDIA_HOUSE_USER, ROLE_PRESENTER
} from 'src/app/utils/authorites';
import { empty } from 'src/app/utils/functions';
import { ONE_DAY } from 'src/app/utils/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isLogging: boolean;
  alertMessage: any;
  alertClass: string;
  alertUserLogin: boolean;
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedService: SharedDataService) {
    this.loginForm = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
    this.routeActivationCheck();

  }

  ngOnInit() {
  }

  routeActivationCheck() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const isAuthorized = params.isAuthorized || null;
      if (isAuthorized) {
        this.alertUserLogin = true;
        this.alertClass = 'alert-primary';
        this.alertMessage = 'alert-responses.error 401';
      }
      const isLoggedIn = params.isLoggedIn || null;
      if (isLoggedIn) {
        this.alertUserLogin = true;
        this.alertClass = 'alert-primary';
        this.alertMessage = 'alert-responses.error-signin-to-continue';
      }
    });
  }
  login() {
    this.isLogging = true;
    this.authService.login(this.loginForm.value).subscribe((res: any) => {
      this.isLogging = false;
      const date = new Date();
      localStorage.setItem('token', res.accessToken);
      this.setUser();

    }, (err: HttpErrorResponse) => {
      this.isLogging = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else if (err.status === 0) {
        this.alertMessage = 'alert-responses.error-500';
      } else {
        this.alertMessage = 'alert-response.error-login-failed';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserLogin = true;
    });
  }

  setUser() {
    const { user } = this.authService.loggedIn();
    if (!user) { return false; }
    this.sharedService.setAuthUser(user);
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      this.routeUser(authority.authority);
      return;
    });
  }

  routeUser(role) {
    if (role === ADVERTISER_ADMIN || role === ADVERTISER_USER) {
      this.router.navigate(['/advertiser']);
    } else if (role === COMPANY_ADMIN || role === COMPANY_USER) {
      this.router.navigate(['/company']);
    } else if (role === ROLE_ADMIN || role === ROLE_USER) {
      this.router.navigate(['/super-admin']);
    } else if (role === MEDIA_HOUSE_ADMIN || role === MEDIA_HOUSE_USER) {
      this.router.navigate(['/media-house']);
    } else if (role === ROLE_PRESENTER) {
      this.router.navigate(['/journalist/management']);
    } else if (role === TRAFFIC_CONTROLLER) {
      this.router.navigate(['/traffic-controller/management']);
    }
  }
}
