import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { empty } from 'src/app/utils/functions';
import { HttpErrorResponse } from '@angular/common/http';
import { DIGIT_PATTERN } from 'src/app/utils/constants';
import {
  ADVERTISER_ADMIN, ADVERTISER_USER, COMPANY_ADMIN, COMPANY_USER,
  MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER, ROLE_ADMIN, ROLE_PRESENTER, ROLE_USER
} from 'src/app/utils/authorites';
import { MediaHouseService } from 'src/app/services/media-house/media-house.service';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {

  editForm: FormGroup;
  alertMessage: any;
  alertClass: string;
  profilePictureAvailable: boolean;
  alertUserAction: boolean;
  userId: number;
  isEditting: boolean;
  userRole: string;
  user: any;
  uploadedImage: boolean;
  userProfilePath: string;
  fileToUpload: File = null;
  file: File = null;
  constructor(
    private sharedService: SharedDataService,
    private mediaHouseService: MediaHouseService,
    private companyService: CompanyService,
    private userService: UserService) {
    this.editForm = new FormGroup({
      advertiserId: new FormControl(null),
      mediaHouse: new FormControl(null),
      companyId: new FormControl(null),
      firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      gender: new FormControl('', [Validators.required]),
      roleName: new FormControl(null),
      status: new FormControl(null),
      mobile: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.pattern(DIGIT_PATTERN), Validators.maxLength(12)]),
      nationalId: new FormControl('', [Validators.required, Validators.minLength(16), Validators.maxLength(16)])
    });

    this.getRole();
  }

  ngOnInit() {
  }

  getRole() {
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      this.userRole = authority.authority;
      this.getUser();
    });
  }

  getUser() {
    this.sharedService.getUserDetails().subscribe((res: any) => {
      if (empty(res)) { return; }
      this.userId = res.id;
      this.user = res;
      if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER || this.userRole === ROLE_PRESENTER) {
        this.editForm.patchValue({
          mediaHouse: res.mediaHouse.id,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          gender: res.gender,
          status: res.status,
          roleName: this.userRole,
          mobile: res.mobile,
          nationalId: res.nationalId,
        });
      } else if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
        this.editForm.patchValue({
          companyId: res.company.id,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          gender: res.gender,
          status: res.status,
          roleName: this.userRole,
          mobile: res.mobile,
          nationalId: res.nationalId,
        });
      } else if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
        this.editForm.patchValue({
          advertiserId: res.advertiser.id,
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          gender: res.gender,
          status: res.status,
          roleName: this.userRole,
          mobile: res.mobile,
          nationalId: res.nationalId,
        });
      } else if (this.userRole === ROLE_ADMIN || this.userRole === ROLE_USER) {
        this.editForm.patchValue({
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          gender: res.gender,
          status: res.status,
          roleName: this.userRole,
          mobile: res.mobile,
          nationalId: res.nationalId,
        });
      }
    });
  }

  editUser() {
    if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER || this.userRole === ROLE_PRESENTER) {
      this.editMediaHouseUser();
    } else if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
      this.editCompanyUser();
    } else if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
      this.editAdvertiserUser();
    } else if (this.userRole === ROLE_ADMIN || this.userRole === ROLE_USER) {
      this.editSuperUser();
    }
  }

  editAdvertiserUser() {
    this.isEditting = true;
    this.userService.updateAdvertiserUser(this.userId, this.editForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserAction = true;
      this.isEditting = false;
      this.clearAlertUsers(4000);
    }, (err: HttpErrorResponse) => {
      this.isEditting = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserAction = true;
    });
  }


  editMediaHouseUser() {
    this.isEditting = true;
    this.userService.updateMediaHouseUser(this.userId, this.editForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserAction = true;
      this.isEditting = false;
      this.clearAlertUsers(4000);
    }, (err: HttpErrorResponse) => {
      this.isEditting = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserAction = true;
    });
  }


  editCompanyUser() {
    this.isEditting = true;
    this.userService.updateCompanyUser(this.userId, this.editForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserAction = true;
      this.isEditting = false;
      this.clearAlertUsers(4000);
    }, (err: HttpErrorResponse) => {
      this.isEditting = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserAction = true;
    });
  }

  editSuperUser() {
    this.isEditting = true;
    this.userService.updateAdvertiserUser(this.userId, this.editForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserAction = true;
      this.isEditting = false;
      this.clearAlertUsers(4000);
    }, (err: HttpErrorResponse) => {
      this.isEditting = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserAction = true;
    });
  }

  clearAlertUsers(secs: number) {
    setTimeout(() => {
      this.alertUserAction = false;
      this.alertClass = '';
      this.alertMessage = '';
    }, secs);
  }
}
