import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { SharedDataService } from './services/shared/shared-data.service';
import { empty } from './utils/functions';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'e-advert';

  constructor(
    private authService: AuthService, private sharedService: SharedDataService,
    private userService: UserService) { }

  ngOnInit() {
    this.setUser();
  }

  setUser() {
    const { user } = this.authService.loggedIn();
    if (!user && !this.authService.tokenExpired()) { return false; }
    this.sharedService.setAuthUser(user);
    this.getUser();
  }
  getUser() {
    this.sharedService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) { return; }
      this.userService.getUser(user.id).subscribe(res => {
        this.sharedService.setUserDetails(res);
      });
    });
  }
}

