import { AdvertViewContentComponent } from './pages/management/adverts/advert-view-content/advert-view-content.component';
import { TrafficControllerComponent } from './layouts/traffic-controller/traffic-controller.component';
import { GeneralComponent } from './layouts/general/general.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from './components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanyAdminComponent } from './layouts/company-admin/company-admin.component';
import { SuperAdminComponent } from './layouts/super-admin/super-admin.component';
import { AdvertiserComponent } from './layouts/advertiser/advertiser.component';
import { JournalistComponent } from './layouts/journalist/journalist.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';

import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { TokenInterceptorService } from './services/interceptors/token/token-interceptor.service';
import { LanguageInterceptorService } from './services/interceptors/language/language-interceptor.service';
import { MediaHouseAdminComponent } from './layouts/media-house-admin/media-house-admin.component';
import { AdvertInvoiceComponent } from './pages/management/adverts/advert-invoice/advert-invoice.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    CompanyAdminComponent,
    GeneralComponent,
    SuperAdminComponent,
    AdvertiserComponent,
    JournalistComponent,
    PageNotFoundComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    MediaHouseAdminComponent,
    TrafficControllerComponent,
    AdvertInvoiceComponent,
    AdvertViewContentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),

    FormsModule,
    ComponentModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true,
    }
  ],

})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['English', 'Francais', 'Kinyarwanda']);
    const cachedLang = localStorage.getItem('user-language');
    const lang = (cachedLang) ? cachedLang : 'English';
    translate.use(lang);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}