import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { checkEditPasswords } from 'src/app/utils/validators';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  setPasswordForm: FormGroup;
  isSettingPassword: boolean;
  alertMessage: any;
  alertClass: string;
  user: any;
  alertUserSetPassword: boolean;
  constructor(private userService: UserService, private sharedService: SharedDataService) {
    this.setPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)
        , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/)]),
      oldPassword: new FormControl('', [Validators.required]),
      c_password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    this.setPasswordForm.setValidators(checkEditPasswords);
    this.getUser();
  }
  ngOnInit() {
  }

  getUser() {
    this.sharedService.getAuthUser().subscribe(res => {
      this.user = res;
    });
  }

  setPassword() {
    this.isSettingPassword = true;
    this.userService.changePassword(this.user.id, this.setPasswordForm.value).subscribe((res: any) => {
      this.isSettingPassword = false;
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.clearAlertUsers(4000);
      this.alertUserSetPassword = true;
    }, (err: HttpErrorResponse) => {
      this.isSettingPassword = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUserSetPassword = true;
    });
  }
  clearAlertUsers(secs: number) {
    setTimeout(() => {
      this.alertUserSetPassword = false;
      this.alertClass = '';
      this.alertMessage = '';
    }, secs);
  }
}
