import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { COMPANY_ADMIN, COMPANY_USER } from 'src/app/utils/authorites';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class CompanyAdminGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedDataService) { }

  canActivate() {
    if (this.isCompanyAdmin()) {
      return true;
    } else {
      this.router.navigate(['/signin'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isCompanyAdmin() {
    let isUserCompanyAdmin: boolean;
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;
      if (role === COMPANY_ADMIN || role === COMPANY_USER) {
        isUserCompanyAdmin = true;
      } else {
        isUserCompanyAdmin = false;
      }
    });
    if (started) { return isUserCompanyAdmin; }
  }


}
