import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { CompanyService } from 'src/app/services/company/company.service';
import { MediaHouseService } from 'src/app/services/media-house/media-house.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Location } from '@angular/common';

@Component({
  selector: 'app-advert-view-content',
  templateUrl: './advert-view-content.component.html',
  styleUrls: ['./advert-view-content.component.scss']
})
export class AdvertViewContentComponent implements OnInit, AfterViewInit {

  showPrint: boolean;
  companyData: any;
  customerData: any;
  advertContent: any;
  bankAccounts: any[] = [];
  QRCODE_DATA: string;
  emptyCount: number;
  selectedPackages: any = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  constructor(
    private sharedService: SharedDataService,
    private _location: Location,
    private mediaHouseService: MediaHouseService,
    private companyService: CompanyService) {
    this.showPrint = true;
    this.emptyCount = 0;
    this.getDetails();
  }

  ngOnInit() {
  }

  getDetails() {
    this.sharedService.getRunningAdvertDetails().subscribe((res: any) => {
      if (empty(res)) {
        return;
      }

      const mediaHouse = res.mediaHouse;
      this.customerData = {
        name: res.advertiser.name,
        address: res.advertiser.address || 'Not Provided',
        tin: res.advertiser.tinNumber || 'Not Provided',
        mobile: res.advertiser.contactPhone || 'Not Provided',
        nationalId: res.advertiser.nationId || 'Not Provided',
      };

      if (res.advertContent) {
        this.advertContent = {
          content: res.advertContent[res.advertContent.length - 1].advertText,
          title: res.advertContent[res.advertContent.length - 1].name,
          package: res.advertRendered.header,
          amountPaid: res.advertRendered.totalPrice.toLocaleString(),
        };

      }


      if (mediaHouse.invoiceAllowed) {
        this.mediaHouseService.getMediaHouseById(mediaHouse.id).subscribe((details: any) => {
          this.companyData = {
            name: details.name,
            tin: details.tinNumber || 'Not Provided',
            phone: details.contactPhone || 'Not Provided',
            address: details.address || 'Not Provided',
          };
          const object = {
            initiator: {
              name: this.companyData.name,
              tin: this.companyData.tin,
            },
            customer: {
              name: this.customerData.name,
              nationalId: this.customerData.nationalId,
            },
            advert: {
              title: this.advertContent.title,
              amount: this.advertContent.amountPaid
            }
          };
          this.QRCODE_DATA = JSON.stringify(object);
        });
      } else {
        this.companyService.getCompanyById(mediaHouse.company.id).subscribe((details: any) => {
          this.companyData = {
            name: details.name,
            tin: details.tinNumber || 'Not Provided',
            phone: details.contactPhone || 'Not Provided',
            address: details.address || 'Not Provided',
          };

          const object = {
            initiator: {
              name: this.companyData.name,
              tin: this.companyData.tin,
            },
            customer: {
              name: this.customerData.name,
              nationalId: this.customerData.nationalId,
            },
            advert: {
              title: this.advertContent.title,
              amount: this.advertContent.amountPaid
            }
          };

          this.QRCODE_DATA = JSON.stringify(object);
        });
      }



    });
  }

  ngAfterViewInit() {
    Feather.replace();
  }

  printInvoice() {
    this.showPrint = false;
    setTimeout(() => {
      this.showPrint = true;
    }, 1000);
    setTimeout(() => {
      window.print();
    }, 100);
  }

  goBack() {
    this._location.back();
  }
}
