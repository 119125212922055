import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    Feather.replace();
  }

}
