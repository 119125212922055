import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as $ from 'jquery';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  loggedIn: boolean;
  user: any;
  username: string;
  userRole: string;
  isDashboard: boolean;
  path: string;
  constructor(
    private sharedService: SharedDataService,
    private authService: AuthService,
    private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;
        if (this.path.includes('account')) {
          this.isDashboard = false;
        }
      }
    });
    this.isDashboard = true;
    this.loggedIn = this.authService.loggedIn();
    this.getRole();
  }

  getRole() {
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      this.userRole = authority.authority;

      this.getUser();
    });
  }

  ngOnInit() {
  }

  setDashboard() {
    this.isDashboard = true;
  }
  getUser() {
    this.sharedService.getAuthUser().subscribe((user: any) => {
      if (empty(user)) { return; }
      this.user = user;
      this.username = this.user.name;
    });
  }

  logout() {
    this.authService.logout();
  }

  callPage(id) {
    const element = $('#' + id);
    element.siblings().removeClass('active');
    element.addClass('active');
  }
}
