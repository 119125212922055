import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit, AfterViewInit {

  user: any;
  constructor(
    private sharedService: SharedDataService) {
    this.getUser();
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    Feather.replace();
  }

  getUser() {
    this.sharedService.getUserDetails().subscribe((user: any) => {
      if (empty(user)) { return; }
      this.user = user;
    });
  }
}
