import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageInterceptorService {

  constructor() { }

  intercept(req, next) {
    const cachedLang = localStorage.getItem('user-language');

    const lang = (cachedLang) ? cachedLang : 'English';

    const useLanguage = (lang === 'English') ? 'us' : (lang === 'Francais') ? 'fr' :
      (lang === 'Kinyarwanda') ? 'rw_RW' : 'us';

    const langRequest = req.clone({
      headers: req.headers.set('Accept-Language', useLanguage)
    });

    return next.handle(langRequest);
  }
}
