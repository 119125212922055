import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translate',
  template: `
  <select #langSelect (change)="setLanguage(langSelect.value)">
    <option
      *ngFor="let lang of translate.getLangs()"
      [value]="lang"
      [attr.selected]="lang === translate.currentLang ? '' : null"
    >{{lang}}</option>
  </select>
`,
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {

  }

  setLanguage(language: string) {
    localStorage.setItem('user-language', language);
    this.translate.use(language);
  }


}

