import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { UserService } from 'src/app/services/user/user.service';
import { DIGIT_PATTERN } from 'src/app/utils/constants';
import { empty } from 'src/app/utils/functions';
import * as Feather from 'feather-icons';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ADVERTISER_ADMIN, ADVERTISER_USER, COMPANY_ADMIN,
  COMPANY_USER, MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER, ROLE_ADMIN, ROLE_PRESENTER, ROLE_USER
} from 'src/app/utils/authorites';


@Component({
  selector: 'app-company-account-info',
  templateUrl: './company-account-info.component.html',
  styleUrls: ['./company-account-info.component.scss']
})
export class CompanyAccountInfoComponent implements OnInit, AfterViewInit {
  company: any;
  companyForm: FormGroup;
  advertiserType: FormControl;
  alertMessage: any;
  alertClass: string;
  alertUser: boolean;
  userRole: string;
  isSaving: boolean;
  constructor(
    private sharedService: SharedDataService,
    private userService: UserService) {
    this.companyForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      contactEmail: new FormControl('', [Validators.required, Validators.email]),
      tinNumber: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      accronym: new FormControl('', [Validators.required]),
      contactPhone: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.pattern(DIGIT_PATTERN), Validators.maxLength(12)])
    });
    this.getRole();
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    Feather.replace();
  }

  getRole() {
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      this.userRole = authority.authority;
      this.getUser();
    });
  }


  getUser() {
    this.sharedService.getUserDetails().subscribe((user: any) => {
      if (empty(user)) { return; }
      if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER) {
        this.company = user.mediaHouse;
      } else if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
        this.company = user.company;
      } else if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
        this.company = user.advertiser;
      }
    });
  }
}
