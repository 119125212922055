import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { CompanyService } from 'src/app/services/company/company.service';
import { MediaHouseService } from 'src/app/services/media-house/media-house.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Location } from '@angular/common';

@Component({
  selector: 'app-advert-invoice',
  templateUrl: './advert-invoice.component.html',
  styleUrls: ['./advert-invoice.component.scss']
})
export class AdvertInvoiceComponent implements OnInit, AfterViewInit {

  showPrint: boolean;
  companyData: any;
  customerData: any;
  paymentData: any;
  bankAccounts: any[] = [];
  QRCODE_DATA: string;
  showVAT: boolean = true;
  foundAnnouncements: boolean = false;
  emptyCount: number;
  selectedPackages: any = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  constructor(
    private sharedService: SharedDataService,
    private _location: Location,
    private mediaHouseService: MediaHouseService,
    private companyService: CompanyService) {
    this.showPrint = true;
    this.emptyCount = 0;
    this.getDetails();
  }

  ngOnInit() {
  }

  getDetails() {
    this.sharedService.getRunningAdvertDetails().subscribe((res: any) => {
      if (empty(res)) {
        return;
      }
      const mediaHouse = res.mediaHouse;


      if (mediaHouse.invoiceAllowed) {
        this.mediaHouseService.getMediaHouseById(mediaHouse.id).subscribe((details: any) => {
          this.companyData = {
            name: details.name,
            tin: details.tinNumber,
            phone: details.contactPhone,
            address: details.address,
            logo: details.logo || null
          };
          this.mediaHouseService.getBankAccounts(mediaHouse.id, 0).subscribe((res: any) => {
            this.bankAccounts = res.content;
          });
          this.QRCODE_DATA = JSON.stringify(this.companyData);
        });
      } else {
        this.companyService.getCompanyById(mediaHouse.company.id).subscribe((details: any) => {
          this.companyData = {
            name: details.name,
            tin: details.tinNumber,
            phone: details.contactPhone,
            address: details.address,
            logo: details.logo || null
          };

          this.companyService.getBankAccounts(mediaHouse.company.id, 0).subscribe((res: any) => {
            this.bankAccounts = res.content;

          });

          this.QRCODE_DATA = JSON.stringify(this.companyData);

        });
      }
      this.customerData = {
        name: res.advertiser.name,
        address: res.advertiser.address,
        phone: res.advertiser.contactPhone,
        tin: res.advertiser.tinNumber || 'N/A',
        nationalId: res.advertiser.nationId
      };

      for (const fixedDuration of res.fixedDurationPackages) {
        fixedDuration.description = `Fixed Duration - ${fixedDuration.adDuration}${fixedDuration.adDurationType},
        ${fixedDuration.dailyFrequency}X/day, ${fixedDuration.runTimedays}days`;
        fixedDuration.unitPrice = fixedDuration.tarrif.tarrif.toLocaleString();
        fixedDuration.totalAmount = fixedDuration.totalPrice.toLocaleString();
        this.selectedPackages.push(fixedDuration);
      }
      for (const fixedWord of res.fixedWordPackages) {
        fixedWord.description = `Fixed Words ${fixedWord.adWords}${fixedWord.adWordType},
        ${fixedWord.dailyFrequency}X/day, ${fixedWord.runTimedays}days`;
        fixedWord.unitPrice = fixedWord.tarrif.tarrif.toLocaleString();
        fixedWord.totalAmount = fixedWord.totalPrice.toLocaleString();
        fixedWord.isFixedWord = true;

        this.selectedPackages.push(fixedWord);
      }
      for (const tieredAdPackage of res.tieredAdPackages) {
        tieredAdPackage.description =
          `${tieredAdPackage.tarrif.showTimeFrame.showProgram.name}: ${tieredAdPackage.tarrif.showTimeFrame.showHourRange}
          ${tieredAdPackage.adDuration}${tieredAdPackage.adDurationType},
        ${tieredAdPackage.dailyFrequency}X/day, ${tieredAdPackage.runTimedays}days`;
        tieredAdPackage.unitPrice = tieredAdPackage.tarrif.tarrif.toLocaleString();
        tieredAdPackage.totalAmount = tieredAdPackage.totalPrice.toLocaleString();

        this.selectedPackages.push(tieredAdPackage);
      }
      for (const announcementInfo of res.announcementInfo) {

        
        announcementInfo.description = `Announcement ${announcementInfo.tarrif.announcementType.engName}: 
        ${announcementInfo.dailyFrequency} X/day,${announcementInfo.runTimedays}days`;
        announcementInfo.unitPrice = announcementInfo.tarrif.tarrif.toLocaleString();
        announcementInfo.totalAmount = announcementInfo.totalPrice.toLocaleString();
        
        this.foundAnnouncements = true;

        this.selectedPackages.push(announcementInfo);
      }
      for (const liveShowInfo of res.liveShowInfo) {
        liveShowInfo.description = `Live Show - ${liveShowInfo.showName} ${liveShowInfo.showDuration}${liveShowInfo.showDurationType},
        ${liveShowInfo.dailyFrequency}X/day, ${liveShowInfo.runTimedays}days`;
        liveShowInfo.unitPrice = liveShowInfo.tarrif.tarrif.toLocaleString();
        liveShowInfo.totalAmount = liveShowInfo.totalPrice.toLocaleString();

        this.selectedPackages.push(liveShowInfo);
      }
      if (res.paymentInfo) {
        const amount = res.paymentInfo[res.paymentInfo.length - 1].amountPaid;

        this.paymentData = {
          method: res.paymentInfo[res.paymentInfo.length - 1].paymentMethod,
          transactionId: res.paymentInfo[res.paymentInfo.length - 1].transactionId,
          account: res.paymentInfo[res.paymentInfo.length - 1].msisdn || res.paymentInfo[res.paymentInfo.length - 1].cardNumber ||
            res.paymentInfo[res.paymentInfo.length - 1].beneficiaryBankAccount,
          date: `${res.paymentInfo[res.paymentInfo.length - 1].dateTime[2] + '/' +
            res.paymentInfo[res.paymentInfo.length - 1].dateTime[1] + '/' + res.paymentInfo[res.paymentInfo.length - 1].dateTime[0]}
             ${res.paymentInfo[res.paymentInfo.length - 1].dateTime[3]}:${res.paymentInfo[res.paymentInfo.length - 1].dateTime[4]}:${res.paymentInfo[res.paymentInfo.length - 1].dateTime[5]}`,
          subtotal: amount.toLocaleString(),
          vat: (0.18 * amount).toLocaleString(),
          totalBill: res.paymentInfo[res.paymentInfo.length - 1].totalBill.toLocaleString(),
          discountPercentage: res.paymentInfo[res.paymentInfo.length - 1].discountPercentage,
          status: res.paymentInfo[res.paymentInfo.length - 1].paymentStatus,
          totalDiscount: res.paymentInfo[res.paymentInfo.length - 1].totalDiscount.toLocaleString(),
        };

        console.log(this.paymentData)


      }
      this.QRCODE_DATA = JSON.stringify(this.companyData);

      this.setupVAT();
    });
  }


  setupVAT() {
    if (this.foundAnnouncements) {
      if (this.selectedPackages.length === 1) {
        for (const selected of this.selectedPackages) {
          if (selected.tarrif.announcementType.ussdFlow === 'KUBIKA_FLOW') {
              this.showVAT = false;
              this.paymentData.totalBill = this.paymentData.subtotal;
          }
        }
      }
    }
  }
  ngAfterViewInit() {
    Feather.replace();
  }

  printInvoice() {
    this.showPrint = false;
    setTimeout(() => {
      this.showPrint = true;
    }, 1000);
    setTimeout(() => {
      window.print();
    }, 100);
  }

  goBack() {
    this._location.back();
  }
}
