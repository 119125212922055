import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER } from 'src/app/utils/authorites';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class MediaHouseAdminGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedDataService) { }

  canActivate() {
    if (this.isMediaHouseAdmin()) {
      return true;
    } else {
      this.router.navigate(['/signin'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isMediaHouseAdmin() {
    let isUserMediaHouseAdmin: boolean;
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;


      if (role === MEDIA_HOUSE_ADMIN || role === MEDIA_HOUSE_USER) {
        isUserMediaHouseAdmin = true;
      } else {
        isUserMediaHouseAdmin = false;
      }
    });
    if (started) { return isUserMediaHouseAdmin; }
  }

}
