import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { DIGIT_PATTERN } from 'src/app/utils/constants';
import { empty } from 'src/app/utils/functions';
import {
  ADVERTISER_ADMIN, ADVERTISER_USER, COMPANY_ADMIN,
  COMPANY_USER, MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER, ROLE_ADMIN, ROLE_PRESENTER, ROLE_USER
} from 'src/app/utils/authorites';
import { UserService } from 'src/app/services/user/user.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CompanyService } from 'src/app/services/company/company.service';
import { MediaHouseService } from 'src/app/services/media-house/media-house.service';

@Component({
  selector: 'app-company-edit-profile',
  templateUrl: './company-edit-profile.component.html',
  styleUrls: ['./company-edit-profile.component.scss']
})
export class CompanyEditProfileComponent implements OnInit {

  company: any;
  companyForm: FormGroup;
  advertiserType: FormControl;
  alertMessage: any;
  alertClass: string;
  alertUser: boolean;
  uploadedImage: boolean;
  file: File = null;
  user: any;
  logoPath: string;
  fileToUpload: File = null;
  userRole: string;
  isSaving: boolean;
  constructor(
    private sharedService: SharedDataService,
    private companyService: CompanyService,
    private mediaHouseService: MediaHouseService,
    private userService: UserService) {
    this.companyForm = new FormGroup({
      id: new FormControl({ value: null, disabled: true }),
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      contactEmail: new FormControl('', [Validators.required, Validators.email]),
      tinNumber: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      company: new FormControl(null),
      status: new FormControl(null),
      accronym: new FormControl(null),
      type: new FormControl(null),
      invoiceAllowed: new FormControl(null),
      contactPhone: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.pattern(DIGIT_PATTERN), Validators.maxLength(12)])
    });
  }

  ngOnInit() {
    this.getRole();

  }

  getRole() {
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      this.userRole = authority.authority;
      this.getUser();
    });
  }

  getUser() {
    this.sharedService.getUserDetails().subscribe((user: any) => {
      if (empty(user)) { return; }
      this.user = user;
      if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER) {
        this.mediaHouseService.getMediaHouseById(user.mediaHouse.id).subscribe((res: any) => {
          this.company = res;

          if (this.company.logo) {
            this.logoPath = this.company.logo.url;
          }
          this.companyForm.patchValue({
            id: this.company.id,
            name: this.company.name,
            company: res.company.id,
            contactEmail: this.company.contactEmail,
            tinNumber: this.company.tinNumber,
            type: this.company.type,
            invoiceAllowed: this.company.invoiceAllowed,
            status: this.company.status,
            address: this.company.address,
            accronym: this.company.accronym,
            contactPhone: this.company.contactPhone
          });
        });
      } else if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
        this.companyService.getCompanyById(user.company.id).subscribe((res: any) => {
          this.company = res;

          if (this.company.logo) {
            this.logoPath = this.company.logo.url;
          }
          this.companyForm.patchValue({
            id: this.company.id,
            name: this.company.name,
            contactEmail: this.company.contactEmail,
            tinNumber: this.company.tinNumber,
            type: this.company.type,
            status: this.company.status,

            address: this.company.address,
            accronym: this.company.accronym,
            contactPhone: this.company.contactPhone
          });
        });
      } else if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
        this.userService.getAdvertiserCompanyById(user.advertiser.id).subscribe((res: any) => {
          this.company = res;

          if (this.company.logo) {
            this.logoPath = this.company.logo.url;
          }
          this.companyForm.patchValue({
            id: this.company.id,
            name: this.company.name,
            contactEmail: this.company.contactEmail,
            tinNumber: this.company.tinNumber,
            type: this.company.type,
            status: this.company.status,
            address: this.company.address,
            accronym: this.company.accronym,
            contactPhone: this.company.contactPhone
          });
        });
      }
    });
  }

  editCompany() {
    if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER) {
      this.editMediaHouse();
    }
    if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
      this.editAgencyCompany();
    }
    if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
      this.editAdvertiserCompany();
    }
  }
  editAgencyCompany() {
    this.isSaving = true;
    this.companyService.update(this.companyForm.get('id').value, this.companyForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUser = true;
      this.isSaving = false;
      this.clearAlertUsers(4000);
      this.getUser();
    }, (err: HttpErrorResponse) => {
      this.isSaving = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUser = true;
      this.clearAlertUsers(4000);
    });
  }

  editAdvertiserCompany() {
    this.isSaving = true;
    this.userService.updateAdvertiserCompany(this.companyForm.get('id').value, this.companyForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUser = true;
      this.isSaving = false;
      this.clearAlertUsers(4000);
      this.getUser();
    }, (err: HttpErrorResponse) => {
      this.isSaving = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUser = true;
      this.clearAlertUsers(4000);
    });
  }


  editMediaHouse() {

    this.isSaving = true;
    this.mediaHouseService.update(this.companyForm.get('id').value, this.companyForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUser = true;
      this.isSaving = false;
      this.clearAlertUsers(4000);
      this.getUser();
    }, (err: HttpErrorResponse) => {
      this.isSaving = false;
      if (err.error.apierror) {
        this.alertMessage = err.error.apierror.message;
      } else {
        this.alertMessage = 'alert-responses.error-500';
      }
      this.alertClass = 'alert-danger-lighten';
      this.alertUser = true;
      this.clearAlertUsers(4000);

    });
  }
  handleFileInput(files: FileList) {
    this.uploadedImage = false;
    this.file = files.item(0);
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (event: any) => {
      this.fileToUpload = event.target.result;
    };
  }
  uploadImage() {
    if (this.userRole === MEDIA_HOUSE_ADMIN || this.userRole === MEDIA_HOUSE_USER) {
      this.uploadMediaHouseLogo();
    } else if (this.userRole === COMPANY_ADMIN || this.userRole === COMPANY_USER) {
      this.uploadCompanyLogo();
    } else if (this.userRole === ADVERTISER_ADMIN || this.userRole === ADVERTISER_USER) {
      this.uploadAdvertiserLogo();
    }
    return;
  }

  uploadMediaHouseLogo() {
    const fileData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    fileData.append('logo', this.file);
    this.mediaHouseService.uploadMediaHouseLogo(this.user.mediaHouse.id, fileData, headers).subscribe((res: any) => {
      this.getUser();
      this.uploadedImage = true;
    });
  }
  uploadCompanyLogo() {
    const fileData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    fileData.append('logo', this.file);
    this.companyService.uploadCompanyLogo(this.user.company.id, fileData, headers).subscribe((res: any) => {
      this.getUser();
      this.uploadedImage = true;
    });
  }
  uploadAdvertiserLogo() {
    const fileData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    fileData.append('logo', this.file);
    this.userService.uploadAdvertiserLogo(this.user.advertiser.id, fileData, headers).subscribe((res: any) => {
      this.getUser();
      this.uploadedImage = true;
    });
  }
  // uploadUserProfile() {
  //   const fileData = new FormData();
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Accept', 'application/json');
  //   fileData.append('logo', this.file);
  //   this.userService.uploadAdvertiserLogo(this.user.advertiser.id, fileData, headers).subscribe((res: any) => {
  //     this.getUser();
  //   });
  // }

  clearAlertUsers(secs: number) {
    setTimeout(() => {
      this.alertUser = false;
      this.alertClass = '';
      this.alertMessage = '';
    }, secs);
  }

}

