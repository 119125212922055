import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';;
import { ROLE_ADMIN, ROLE_USER } from 'src/app/utils/authorites';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedDataService) { }

  canActivate() {
    if (this.isSuperAdmin()) {
      return true;
    } else {
      this.router.navigate(['/signin'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isSuperAdmin() {
    let isUserSuperAdmin: boolean;
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;
      if (role === ROLE_ADMIN || role === ROLE_USER) {
        isUserSuperAdmin = true;
      } else {
        isUserSuperAdmin = false;
      }
    });
    if (started) { return isUserSuperAdmin; }
  }
}
