import * as _ from 'lodash';

export function empty(object: any) {
    return _.isEmpty(object);
}

export function sort(array, key, asc = false) {
    if (asc) {
        return _.orderBy(array, [key], ['asc']);
    }
    return _.orderBy(array, [key], ['desc']);
}


export function hasWhiteSpace(s) {
    return /\s/g.test(s);
}

export function addProperty(array, value) {
    array.forEach((element: any) => {
        element.editable = value;
    });
    return array;
}
export function addInfoProperty(array, value) {
    array.forEach((element: any) => {
        element.info = value;
    });
    return array;
}

export function filterUsers(array, value) {
    return array.filter((element) => {
        return element.email === value;
    });
}

export function getTotalDiscount(percentage: number, amount: number) {
    if (percentage <= 0) { return 0; }
    return (percentage / 100) * amount;
}
export function getTaxInclusive(percentage: number, amount: number) {
    return (percentage / 100) * amount;
}
export function getTotalBill(discount: number, amount: number, totalTax: number) {
    return (amount - discount) + totalTax;
}

export const formatCash = n => {
    if (n < 1e3) { return n; }
    if (n >= 1e3 && n < 1e6) { return +(n / 1e3).toFixed(1) + 'K'; }
    if (n >= 1e6 && n < 1e9) { return +(n / 1e6).toFixed(1) + 'M'; }
    if (n >= 1e9 && n < 1e12) { return +(n / 1e9).toFixed(1) + 'B'; }
    if (n >= 1e12) { return +(n / 1e12).toFixed(1) + 'T'; }
};

export const formatChartCash = n => {
    if (n < 1e3) { return n; }
    if (n >= 1e3 && n < 1e6) { return +(n / 1e3).toFixed(1); }
    if (n >= 1e6 && n < 1e9) { return +(n / 1e6).toFixed(1); }
    if (n >= 1e9 && n < 1e12) { return +(n / 1e9).toFixed(1); }
    if (n >= 1e12) { return +(n / 1e12).toFixed(1); }
};


export function removeTags(str) {
    if ((str === null) || (str === '')) {
        return false;
    } else {
        str = str.toString();
    }

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
}

export function removeWhiteSpaces(str) {
    if ((str === null) || (str === '')) {
        return false;
    } else {
        str = str.toString();
    }

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/\s/g, '');
}





export function closeModal(button: HTMLButtonElement, seconds: number = 3000) {
    if (!button) return;
    button.setAttribute('type', 'button');
    button.setAttribute('data-dismiss', 'modal');
    setTimeout(() => {
        button.click();
        button.removeAttribute('data-dismiss');
        button.setAttribute('type', 'submit');
    }, seconds);
}

export function countWords(str: string) {
    if (!str) return 0;
    return str.split(' ').length;
}


export function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
  }