import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { StatisticsService } from 'src/app/services/statistics.service';
import { formatCash } from 'src/app/utils/functions';
import { lineColors, datesLineColors } from './../../utils/constants';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  superadmin = true;
  activeType: number;
  activeYear: number;
  activeTypeTxt: string;
  activeMonthTxt: string;
  years: any[] = [];
  activeMonth: number;
  todayData: any;
  gotChartData: boolean;
  monthData: any;
  paymentMethod: any;
  todayChartData: any[] = [];
  monthChartData: any[] = [];
  yearChartData: any[] = [];
  yearData: any;

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };


  statisticsChart: any[] = [];



  chartData3 = [
    {
      data: [700, 0, 400, 0, 200, 50, 500, 700], label: '', borderColor: '#4339f2', fill: '#fffff',
      pointBackgroundColor: '#4339f2',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(103, 58, 183, .8)',

    },
    {
      data: [0, 400, 5, 700, 0, 600, 455], label: '', borderColor: '#ff3a29', fill: '#fffff',
      pointBackgroundColor: '#ff3a29',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
    }
  ];

  statisticsChartLabels: any[] = [];
  chartLabels1 = ['', '', '', '', '', '', ''];
  legend = ['Yes', 'No'];


  options: {

    scales: {
      yAxes: [{
        gridLines: {
          display: false,
          drawBorder: false, // <---
          lineWidth: 27,
          zeroLineWidth: 1
        },
        ticks: {
          beginAtZero: true,
          display: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: true,
          drawBorder: false, // <---
          lineWidth: 47,
          zeroLineWidth: 1
        },
        ticks: {
          display: true
        }
      }],
    }
  };

  constructor(private statisticsService: StatisticsService) {
    this.getYears();
    this.getGeneralStatistics();
    const date = new Date();
    this.activeYear = date.getFullYear();
    this.activeType = 1;
    this.activeTypeTxt = 'DASHBOARD.SUPER-ADMIN.row2.select-types.type1';
    this.activeMonthTxt = 'DASHBOARD.SUPER-ADMIN.row2.select-month.type1';
    this.activeMonth = 1;
    this.getYearStatistics(this.activeYear);
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    Feather.replace();
  }

  getYears() {
    this.statisticsService.getYears().subscribe((res: any) => {
      for (const year of res) {
        this.years.push(year.year);
      }
    });
  }
  getGeneralStatistics() {
    this.statisticsService.getGeneralStatistics().subscribe((res: any) => {
      const { today, currentMonth, currentYear } = res;
      this.todayData = this.getTodayData(today);
      [this.monthData, this.paymentMethod] = this.getMonthData(currentMonth);
      this.yearData = this.getYearData(currentYear);
      this.configureIcons(100);
    });
  }

  getYearStatistics(year) {
    this.statisticsChart = [];
    this.statisticsChartLabels = [];
    this.statisticsService.getYearStatistics(year).subscribe((res: any) => {

      let i = 0;
      const color = lineColors;
      for (const month of res) {
        this.statisticsChartLabels.push('');
        const chartData = {
          data: [...month.data], label: month.month, borderColor: color[i], fill: '#fffff',
          pointBackgroundColor: color[i],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: color[i],
        };
        this.statisticsChart.push(chartData);
        this.gotChartData = true;
        i++;
      }

      this.configureIcons(100);
    });
  }



  getMonthStatistics(year, month) {
    this.statisticsChart = [];
    this.statisticsChartLabels = [];
    this.statisticsService.getMonthStatistics(year, month).subscribe((res: any) => {

      let i = 0;
      const color = datesLineColors;
      for (const date of res) {
        this.statisticsChartLabels.push('');
        const chartData = {
          data: [...date.data], label: date.date, borderColor: color[i], fill: '#fffff',
          pointBackgroundColor: color[i],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: color[i],
        };
        this.statisticsChart.push(chartData);
        this.gotChartData = true;
        i++;
      }

      this.configureIcons(100);
    });
  }


  getTodayData(stats) {
    const increase = stats.revenueStats.revenueIncreased;
    const percentage = stats.revenueStats.revenuePercantageChange.toFixed(0);
    const date = new Date(stats.dateToday);
    const current = date.toDateString();
    let sign = '';
    if (percentage === 0) {
      sign = '';
    } else {
      sign = (increase) ? '+' : '-';
    }


    this.todayChartData = [
      {
        data: [...stats.revenueStats.dataCurrent], label: '', borderColor: '#ff3a29', fill: '#fffff',
        pointBackgroundColor: '#ff3a29',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
      }
    ];

    const data = {
      current,
      total: formatCash(stats.revenueStats.revenueCurrent),
      increase,
      percentage,
      change: `${sign}${percentage}%`
    };


    return data;
  }


  getMonthData(stats) {
    const increase = stats.revenueStats.revenueIncreased;
    const percentage = stats.revenueStats.revenuePercantageChange.toFixed(0);
    let sign = '';
    if (percentage === 0) {
      sign = '';
    } else {
      sign = (increase) ? '+' : '-';
    }
    const nums = [];

    this.monthChartData = [
      {
        data: [...stats.revenueStats.dataCurrent], label: '', borderColor: '#34b53a', fill: '#fffff',
        pointBackgroundColor: '#34b53a',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
      }
    ];

    const data = {
      current: stats.currentMonth,
      total: formatCash(stats.revenueStats.revenueCurrent),
      increase,
      percentage,
      change: `${sign}${percentage}%`
    };

    const payment = {
      bankTransfers: stats.paymentMethodCurrentMonth.bankDepositSalesPercentage,
      cardSales: stats.paymentMethodCurrentMonth.cardSalesPercentage,
      mtnMomo: stats.paymentMethodCurrentMonth.mtnMomoSalesPercentage,
      airtelMomo: stats.paymentMethodCurrentMonth.airtelMomoSalesPercentage,
    };

    return [data, payment];
  }

  getYearData(stats) {
    // const data = {} as any;
    // data.current = stats.currentYear;
    // data.total = formatCash(stats.revenueStats.revenueCurrent);
    // data.increase = stats.revenueStats.revenueIncreased;

    // const sign = (data.increase) ? '+' : '-';
    // data.percentage = stats.revenueStats.revenuePercantageChange.toFixed(0);
    // data.change = `${sign}${data.percentage}%`;

    // this.yearChartData = [
    //   {
    //     data: [...stats.revenueStats.dataCurrent], label: '', borderColor: '#02a0fc', fill: '#fffff',
    //     pointBackgroundColor: '#02a0fc',
    //     pointBorderColor: '#fff',
    //     pointHoverBackgroundColor: '#fff',
    //     pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
    //   }
    // ];
    // return data;


    const increase = stats.revenueStats.revenueIncreased;
    const percentage = stats.revenueStats.revenuePercantageChange.toFixed(0);
    let sign = '';
    if (percentage === 0) {
      sign = '';
    } else {
      sign = (increase) ? '+' : '-';
    }
    const nums = [];

    this.yearChartData = [
      {
        data: [...stats.revenueStats.dataCurrent], label: '', borderColor: '#02a0fc', fill: '#fffff',
        pointBackgroundColor: '#02a0fc',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
      }
    ];
    const data = {
      current: stats.currentYear,
      total: formatCash(stats.revenueStats.revenueCurrent),
      increase,
      percentage,
      change: `${sign}${percentage}%`
    };


    return data;
  }





  configureIcons(sec) {
    setTimeout(() => {
      Feather.replace();
    }, sec);
  }

  getYear(year) {
    this.activeYear = year;
    if (this.activeType === 2) {
      this.getMonthStatistics(this.activeYear, this.activeMonth);
    } else {
      this.getYearStatistics(this.activeYear);
    }
  }

  getMonth(month: number, txt: string) {
    this.activeMonth = month;
    this.activeMonthTxt = txt;
    this.getMonthStatistics(this.activeYear, month);
  }

  changeDurationType(type: number, txt: string) {
    this.activeType = type;
    this.activeTypeTxt = txt;

    if (this.activeType === 2) {
      this.getMonthStatistics(this.activeYear, this.activeMonth + 1);
    }
    if (this.activeType === 1) {
      this.getYearStatistics(this.activeYear);
    }
  }

}
