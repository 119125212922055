import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateComponent } from './translate/translate.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AlertComponent } from './alert/alert.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { CompanyAccountInfoComponent } from './company-account-info/company-account-info.component';
import { CompanyEditProfileComponent } from './company-edit-profile/company-edit-profile.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nModule } from '../i18n/i18n.module';
import { SharedModule } from '../pages/shared/shared.module';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@NgModule({
  declarations: [
    TranslateComponent,
    NavbarComponent,
    DashboardComponent,
    EditAccountComponent,
    AccountInfoComponent,
    ChangePasswordComponent,
    AlertComponent,
    EditPasswordComponent,
    CompanyAccountInfoComponent,
    CompanyEditProfileComponent,
  ],
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot({
      radius: 50,
      outerStrokeWidth: 7,
      showBackground: true,
      showSubtitle: false,
      responsive: true,
      showInnerStroke: false,
      outerStrokeColor: '#78C000',
      animation: true,
      animationDuration: 200,
    }),
    ChartsModule,
    RouterModule,
    ReactiveFormsModule,
    // SharedModule,
    NgxQRCodeModule,
    TranslateModule,
  ],
  providers: [
    ThemeService
  ],
  exports: [
    TranslateComponent,
    NavbarComponent,
    DashboardComponent,
    AccountInfoComponent,
    ChangePasswordComponent,
    NgxQRCodeModule,
    EditAccountComponent,
    AlertComponent,
  ]
})
export class ComponentModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['English', 'Francais', 'Kinyarwanda']);
    const cachedLang = localStorage.getItem('user-language');
    const lang = (cachedLang) ? cachedLang : 'English';
    translate.use(lang);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
