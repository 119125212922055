export const ADVERTISER_ADMIN = 'ADVERTISER_ADMIN';
export const ADVERTISER_USER = 'ADVERTISER_USER';

export const ROLE_PRESENTER = 'ROLE_PRESENTER';

export const COMPANY_ADMIN = 'COMPANY_ADMIN';
export const COMPANY_USER = 'COMPANY_USER';

export const MEDIA_HOUSE_ADMIN = 'MEDIA_HOUSE_ADMIN';
export const MEDIA_HOUSE_USER = 'MEDIA_HOUSE_USER';

export const TRAFFIC_CONTROLLER = 'TRAFFIC_CONTROLLER';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';

