import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { checkPasswords } from 'src/app/utils/validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  setPasswordForm: FormGroup;
  isSettingPassword: boolean;
  alertMessage: any;
  alertClass: string;
  alertUserSetPassword: boolean;
  constructor(private router: Router, private authService: AuthService) {
    this.setPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      activationCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6),
      Validators.pattern(/^-?(0|[0-9]\d*)?$/)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)
        , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/)]),
      c_password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    this.setPasswordForm.setValidators(checkPasswords);
  }
  ngOnInit() {
  }

  setPassword() {
    this.isSettingPassword = true;
    this.authService.setPassword(this.setPasswordForm.value).subscribe(res => {
      this.isSettingPassword = false;
      this.router.navigate(['/signin']);
    }, (err: HttpErrorResponse) => {
      this.isSettingPassword = false;
      this.alertMessage = err.error.apierror.message;
      this.alertClass = 'alert-danger-lighten';
      this.alertUserSetPassword = true;
    });
  }
}
