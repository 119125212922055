import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DIGIT_PATTERN } from './../../utils/constants';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  isRegistering: boolean;
  alertMessage: string;
  alertClass: string;
  alertUserRegistration: boolean;

  constructor(private authService: AuthService, private router: Router) {
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      gender: new FormControl('', [Validators.required]),
      nationalId: new FormControl('', [Validators.required, Validators.pattern(DIGIT_PATTERN),
      Validators.minLength(16), Validators.maxLength(16)]),
      mobile: new FormControl('', [Validators.required, Validators.minLength(10),
      Validators.maxLength(12), Validators.pattern(/^-?(0|[0-9]\d*)?$/)]),
    });
  }

  ngOnInit() {
  }

  register() {
    this.isRegistering = true;
    this.authService.register(this.registerForm.value).subscribe((res: any) => {
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserRegistration = true;
      this.isRegistering = false;
      setTimeout(() => {
        this.router.navigate(['/set-password']);
      }, 5000);
    }, (err: HttpErrorResponse) => {
      this.isRegistering = false;
      this.alertMessage = err.error.apierror.message;
      this.alertClass = 'alert-danger-lighten';
      this.alertUserRegistration = true;
    });
  }
}
