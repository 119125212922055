import { FormGroup } from '@angular/forms';

export function checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('c_password').value;
    return pass === confirmPass ? null : { unmatch: true };
}


export function checkEditPasswords(group: FormGroup) {
    const pass = group.get('newPassword').value;
    const confirmPass = group.get('c_password').value;
    return pass === confirmPass ? null : { unmatch: true };
}
