import { TRAFFIC_CONTROLLER } from './../../../utils/authorites';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER } from 'src/app/utils/authorites';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class TrafficControllerGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedDataService) { }

  canActivate() {
    if (this.isTrafficController()) {
      return true;
    } else {
      this.router.navigate(['/signin'], { queryParams: { isAuthorised: false } });
      return false;
    }
  }

  isTrafficController() {
    let isUserTrafficController: boolean;
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;

      if (role === TRAFFIC_CONTROLLER) {
        isUserTrafficController = true;
      } else {
        isUserTrafficController = false;
      }
    });
    if (started) { return isUserTrafficController; }
  }

}
