import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }
  getAll(page: number) {
    return this.httpClient.get(environment.API_URL + '/api/users' + `?size=7&page=${page}`);
  }

  getUser(id: number) {
    return this.httpClient.get(environment.API_URL + '/api/users/' + id);
  }

  createAdvertiser(user) {
    return this.httpClient.post(environment.API_URL + '/api/users/advertiser-user', user);
  }


  createCompanyUser(user) {
    return this.httpClient.post(environment.API_URL + '/api/users/company-user', user);
  }


  createMediaHouseUser(user) {
    return this.httpClient.post(environment.API_URL + '/api/users/media-house-user', user);
  }

  getAdvs() {
    return this.httpClient.get(environment.API_URL + '/api/advertisers');
  }

  getAllAdvertiserUsers(id: number, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/users/advertiser/' + id + `?size=7&page=${page}`);
  }

  getAlCompanyUsers(id: number, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/users/company/' + id + `?size=10&page=${page}`);
  }

  getAllMediaHouseUsers(id: number, role, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/users/media-house/' + id + '/role/' + role + `?size=10&page=${page}`);
  }

  delete(id) {
    return this.httpClient.delete(environment.API_URL + '/api/users/' + id);
  }



  updateCompanyUser(id, user) {
    return this.httpClient.put(environment.API_URL + '/api/users/company-user/' + id, user);
  }
  updateAdvertiser(id, user) {
    return this.httpClient.put(environment.API_URL + '/api/users/advertiser-user/' + id, user);
  }

  updateMediaHouseUser(id, user) {
    return this.httpClient.put(environment.API_URL + '/api/users/media-house-user/' + id, user);
  }

  getJournalists(id, roleName, page) {
    return this.httpClient.get(environment.API_URL + '/api/users/media-house/' + id + '/role/' + roleName + `?size=7&page=${page}`);
  }

  getByRole(roleName, page) {
    return this.httpClient.get(environment.API_URL + '/api/users/role/' + roleName + `?size=7&page=${page}`);
  }
  resetPassword(email) {
    return this.httpClient.post(environment.API_URL + '/api/auth/initiate-reset-password', email);
  }
  createJournalist(user) {
    return this.httpClient.post(environment.API_URL + '/api/users/media-house-user', user);
  }

  createAdvertiserCompany(company) {
    return this.httpClient.post(environment.API_URL + '/api/advertisers', company);
  }
  updateAdvertiserCompany(id, company) {
    return this.httpClient.put(environment.API_URL + '/api/advertisers/' + id, company);
  }
  updateAdvertiserUser(id, user) {
    return this.httpClient.put(environment.API_URL + '/api/users/advertiser-user/' + id, user);
  }
  getAdvertiserCompanyById(id) {
    return this.httpClient.get(environment.API_URL + '/api/advertisers/' + id);
  }

  uploadAdvertiserLogo(id, logo, headers) {
    return this.httpClient.put(environment.API_URL + '/api/advertisers/logo/' + id, logo, { headers });
  }

  changePassword(id: number, passwords: object) {
    return this.httpClient.put(environment.API_URL + '/api/users/user/' + id + '/change-password', passwords);
  }

  getAllAdvertisers() {
    return this.httpClient.get(environment.API_URL + '/api/advertisers/list');
  }




  searchMediaHouseUsers(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/users/search/media-house/' + id + '/keyword/' + keyword);
  }
  searchCompanyUsers(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/users/search/company/' + id + '/keyword/' + keyword);
  }
  searchAdvertiserCompanyUsers(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/users/search/advertiser/' + id + '/keyword/' + keyword);
  }

}
