import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { ADVERTISER_ADMIN, ADVERTISER_USER, ROLE_ADMIN } from 'src/app/utils/authorites';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserGuard implements CanActivate {
  constructor(private router: Router, private sharedService: SharedDataService) { }

  canActivate() {
    if (this.isAdvertiser()) {
      return true;
    } else {
      this.router.navigate(['/signin'], { queryParams: { isAuthorized: false } });
      return false;
    }
  }

  isAdvertiser() {
    let isUserAdvertiser: boolean;
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;
      if (role === ADVERTISER_ADMIN || role === ADVERTISER_USER) {
        isUserAdvertiser = true;
      } else {
        isUserAdvertiser = false;
      }
    });
    if (started) { return isUserAdvertiser; }
  }

}
