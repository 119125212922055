import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';

@Component({
  selector: 'app-advertiser',
  templateUrl: './advertiser.component.html',
  styleUrls: ['./advertiser.component.scss']
})
export class AdvertiserComponent implements OnInit {

  constructor(private authService: AuthService, private sharedService: SharedDataService) { }

  ngOnInit() {
    this.setUser();
  }

  setUser() {
    const { user } = this.authService.loggedIn();
    if (!user) { return false; }
    this.sharedService.setAuthUser(user);
  }

}
