import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { UserService } from 'src/app/services/user/user.service';
import { checkPasswords } from 'src/app/utils/validators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  email: FormControl;
  changePasswordForm: FormGroup;
  currentStep: number;
  isSendingMail: boolean;
  isChangingPassword: boolean;
  alertMessage: string;
  alertClass: string;
  alertUserChangePassword: boolean;
  alertUserSendEmail: boolean;
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router) {
    this.currentStep = 0;
    this.email = new FormControl('', [Validators.required, Validators.email]);
    this.changePasswordForm = new FormGroup({
      activationCode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6),
      Validators.pattern(/^-?(0|[0-9]\d*)?$/)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)
        , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/)]),
      c_password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    this.changePasswordForm.setValidators(checkPasswords);
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    Feather.replace();
  }

  sendEmail() {
    this.isSendingMail = true;
    this.userService.resetPassword({ email: this.email.value }).subscribe((res: any) => {
      this.isSendingMail = false;
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserSendEmail = true;
      this.currentStep = 1;

    }, err => {
      this.isSendingMail = false;
      this.alertMessage = err.message;
      this.alertClass = 'alert-danger-lighten';
      this.alertUserSendEmail = true;
    });
  }

  changePassword() {
    this.isChangingPassword = true;
    const send = this.changePasswordForm.value;
    send.email = this.email.value;
    this.authService.setPassword(send).subscribe((res: any) => {
      this.isChangingPassword = false;
      this.alertMessage = res.message;
      this.alertClass = 'alert-success-lighten';
      this.alertUserChangePassword = true;
      this.currentStep = 1;
      setTimeout(() => {
        this.router.navigate(['/signin']);
      }, 3000);
    }, err => {
      this.isChangingPassword = false;
      this.alertMessage = err.error.apierror.message;
      this.alertClass = 'alert-danger-lighten';
      this.alertUserChangePassword = true;
    });
  }


}
