import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { empty } from 'src/app/utils/functions';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-media-house-admin',
  templateUrl: './media-house-admin.component.html',
  styleUrls: ['./media-house-admin.component.scss']
})
export class MediaHouseAdminComponent implements OnInit {

  constructor(private sharedService: SharedDataService, private userService: UserService) { }

  ngOnInit() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.sharedService.getAuthUser().subscribe((authUser: any) => {
      if (empty(authUser)) { return; }
      this.userService.getUser(authUser.id).subscribe((user: any) => {
        this.sharedService.setUserDetails(user);
      });
    });
  }

}
