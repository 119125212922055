import { TRAFFIC_CONTROLLER } from './../../../utils/authorites';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';;
import { ADVERTISER_ADMIN, ADVERTISER_USER, COMPANY_ADMIN, COMPANY_USER, MEDIA_HOUSE_ADMIN, MEDIA_HOUSE_USER, ROLE_ADMIN, ROLE_PRESENTER, ROLE_USER } from 'src/app/utils/authorites';
import { SharedDataService } from 'src/app/services/shared/shared-data.service';
import { AuthService } from '../../auth/auth.service';
import { empty } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class GeneralGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private sharedService: SharedDataService) { }

  canActivate() {
    if (!(this.authService.loggedIn())) {
      return true;
    }
    this.router.navigate([this.getUser()]);
  }
  getUser() {
    let ROUTE = '';
    const started = true;
    this.sharedService.getUserAuthority().subscribe((authority: any) => {
      if (empty(authority)) { return; }
      const role = authority.authority;
      if (role === ROLE_ADMIN || role === ROLE_USER) {
        ROUTE = '/super-admin';
      } else if (role === COMPANY_ADMIN || role === COMPANY_USER) {
        ROUTE = '/company';
      } else if (role === MEDIA_HOUSE_USER || role === MEDIA_HOUSE_ADMIN) {
        ROUTE = '/media-house';
      } else if (role === TRAFFIC_CONTROLLER) {
        ROUTE = '/traffic-controller';
      } else if (role === ROLE_PRESENTER) {
        ROUTE = '/journalist';
      } else if (role === ADVERTISER_ADMIN || role === ADVERTISER_USER) {
        ROUTE = '/advertiser';
      }
    });
    if (started) { return ROUTE; }
  }
}
