// tslint:disable-next-line: eofline
export const NUMBERS_PATTERN = /^(0|[1-9][0-9]*)$/;
export const ADVERTS_NUMBER_PATTERN = /^[1-9]\d*$/;
export const DIGIT_PATTERN = /^(0|[0-9][0-9]*)$/;

export const options = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }],               // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
        [{ direction: 'rtl' }],                         // text direction

        [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ['clean'],                                         // remove formatting button

        // ['link', 'image', 'video']                         // link and image, video
    ]
};

/**
 * Announcement Words Limit
 */
export const ANNOUNCEMENT_WORDS_LIMIT = 2000;

export const lineColors = [
    '#0B0014',
    '#00120B',
    '#FB8B24',
    '#0F4C5C',
    '#E36414',
    '#7F636E',
    '#EF2D56',
    '#363537',
    '#0B0014',
    '#643173',
    '#643173',
    '#00BBF9',
];

export const datesLineColors = [
    '#0B0014',
    '#00120B',
    '#FB8B24',
    '#0F4C5C',
    '#E36414',
    '#7F636E',
    '#EF2D56',
    '#363537',
    '#0B0014',
    '#643173',
    '#643173',
    '#00BBF9',
    '#1B4965',
    '#FFD972',
    '#EFA7A7',
    '#5C573E',
    '#A5B452',
    '#C8D96F',
    '#C4F7A1',
    '#912F56',
    '#0D090A',
    '#521945',
    '#E4572E',
    '#F0F66E',
    '#CBD4C2',
    '#50514F',
    '#C3B299',
    '#E5625E',
    '#E5E059',
    '#881600',
    '#C16200',
];
// exports.pointHoverBorderColor = ['rgba(103, 58, 183, .8)',];

export const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
];

export const frenchMonths = [
    'JANVIER',
    'FEVRIER',
    'MARS',
    'AVRIL',
    'MAI',
    'JUIN',
    'JULLIET',
    'AOUT',
    'SEPTEMBRE',
    'OCTOBRE',
    'NOVEMBRE',
    'DECEMBRE'
];



export const ONE_DAY = 24*60*60;
