import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }

  getAll(page) {
    return this.httpClient.get(environment.API_URL + `/api/companies?size=7&page=${page}`);
  }

  getCompanyById(id: number) {
    return this.httpClient.get(environment.API_URL + `/api/companies/` + id);
  }

  create(company) {
    return this.httpClient.post(environment.API_URL + '/api/companies', company);
  }
  update(id, company) {
    return this.httpClient.put(environment.API_URL + '/api/companies/' + id, company);
  }
  delete(id) {
    return this.httpClient.delete(environment.API_URL + '/api/companies/' + id);
  }
  uploadCompanyLogo(id, logo, headers) {
    return this.httpClient.put(environment.API_URL + '/api/companies/logo/' + id, logo, { headers });
  }

  getBankAccounts(id: number, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/bank-accounts/company/' + id + `?size=7&page=${page}`);
  }
  createBankAccounts(company: object) {
    return this.httpClient.post(environment.API_URL + '/api/bank-accounts/company-account', company);
  }
  updateBankAccounts(id: number, company: object) {
    return this.httpClient.put(environment.API_URL + '/api/bank-accounts/company-account/' + id, company);
  }
  deleteBankAccounts(id: number) {
    return this.httpClient.delete(environment.API_URL + '/api/bank-accounts/' + id);
  }
  // /api/bank-accounts/company-account

}
