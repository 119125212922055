import { TrafficControllerGuard } from './services/guards/traffic-controller/traffic-controller.guard';
import { TrafficControllerComponent } from './layouts/traffic-controller/traffic-controller.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SuperAdminComponent } from './layouts/super-admin/super-admin.component';
import { CompanyAdminComponent } from './layouts/company-admin/company-admin.component';
import { JournalistComponent } from './layouts/journalist/journalist.component';
import { AdvertiserComponent } from './layouts/advertiser/advertiser.component';
import { GeneralComponent } from './layouts/general/general.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AdvertiserGuard } from './services/guards/advertiser/advertiser.guard';
import { JournalistGuard } from './services/guards/journalist/journalist.guard';
import { CompanyAdminGuard } from './services/guards/company-admin/company-admin.guard';
import { SuperAdminGuard } from './services/guards/super-admin/super-admin.guard';
import { AuthGuard } from './services/guards/auth/auth.guard';
import { MediaHouseAdminGuard } from './services/guards/media-house-admin/media-house-admin.guard';
import { MediaHouseAdminComponent } from './layouts/media-house-admin/media-house-admin.component';
import { GeneralGuard } from './services/guards/general/general.guard';
import { AdvertInvoiceComponent } from './pages/management/adverts/advert-invoice/advert-invoice.component';
import { AdvertViewContentComponent } from './pages/management/adverts/advert-view-content/advert-view-content.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [GeneralGuard],
    component: GeneralComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/general/general.module').then(module => module.GeneralModule),
      },
    ],
  },
  {
    path: 'super-admin',
    canActivate: [SuperAdminGuard, AuthGuard],
    component: SuperAdminComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/super-admin/super-admin.module').then(module => module.SuperAdminModule),
      },
    ],
  },
  {
    path: 'company',
    component: CompanyAdminComponent,
    canActivate: [CompanyAdminGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/company-admin/company-admin.module').then(module => module.CompanyAdminModule),
      },
    ],
  },
  {
    path: 'media-house',
    component: MediaHouseAdminComponent,
    canActivate: [MediaHouseAdminGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/media-house-admin/media-house-admin.module').then(module => module.MediaHouseAdminModule),
      },
    ],
  },
  {
    path: 'traffic-controller',
    component: TrafficControllerComponent,
    canActivate: [TrafficControllerGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/traffic-controller/traffic-controller.module').then(module => module.TrafficControllerModule),
      },
    ],
  },
  {
    path: 'journalist',
    component: JournalistComponent,
    canActivate: [JournalistGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/journalist/journalist.module').then(module => module.JournalistModule),
      },
    ],
  },
  {
    path: 'advertiser',
    component: AdvertiserComponent,
    canActivate: [AdvertiserGuard, AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/advertiser/advertiser.module').then(module => module.AdvertiserModule),
      },
    ],
  },
  {
    path: 'signin',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: RegisterComponent,
  },
  {
    path: 'set-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'advertiser/advert/invoice',
    canActivate: [AdvertiserGuard, AuthGuard],
    component: AdvertInvoiceComponent,
  },
  {
    path: 'media-house/advert/invoice',
    canActivate: [MediaHouseAdminGuard, AuthGuard],
    component: AdvertInvoiceComponent,
  },

  {
    path: 'traffic-controller/advert/invoice',
    canActivate: [TrafficControllerGuard, AuthGuard],
    component: AdvertInvoiceComponent,
  },

  {
    path: 'advertiser/advert/view-content',
    canActivate: [AdvertiserGuard, AuthGuard],
    component: AdvertViewContentComponent,
  },
  {
    path: 'media-house/advert/view-content',
    canActivate: [MediaHouseAdminGuard, AuthGuard],
    component: AdvertViewContentComponent,
  },
  {
    path: 'traffic-controller/advert/view-content',
    canActivate: [TrafficControllerGuard, AuthGuard],
    component: AdvertViewContentComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
