import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaHouseService {

  constructor(private httpClient: HttpClient) { }
  getAll(page) {
    return this.httpClient.get(environment.API_URL + `/api/media-houses` + `?size=20&page=${page}`);
  }
  getMediaHouseById(id) {
    return this.httpClient.get(environment.API_URL + `/api/media-houses/` + id);
  }

  searchMediaHouse(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/media-houses/company/' + id + '/search/' + keyword);
  }

  getAllByCompany(id, page) {
    return this.httpClient.get(environment.API_URL + `/api/media-houses/company/` + id + `?size=20&page=${page}`);
  }

  create(company) {
    return this.httpClient.post(environment.API_URL + '/api/media-houses', company);
  }
  update(id, company) {
    return this.httpClient.put(environment.API_URL + '/api/media-houses/' + id, company);
  }
  delete(id) {
    return this.httpClient.delete(environment.API_URL + '/api/media-houses/' + id);
  }

  addMediaHouseUser(user) {
    return this.httpClient.post(environment.API_URL + '/api/users/media-house-user', user);
  }

  getMediaHousePrograms(id, page) {
    return this.httpClient.get(environment.API_URL + `/api/show-programs/media-house/` + id + `?size=7&page=${page}`);
  }
  createMediaHouseProgram(program) {
    return this.httpClient.post(environment.API_URL + '/api/show-programs', program);
  }
  uploadMediaHouseLogo(id, logo, headers) {
    return this.httpClient.put(environment.API_URL + '/api/media-houses/logo/' + id, logo, { headers });
  }

  getBankAccounts(id: number, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/bank-accounts/media-house/' + id + `?size=7&page=${page}`);
  }
  createBankAccounts(company: object) {
    return this.httpClient.post(environment.API_URL + '/api/bank-accounts/media-house-account', company);
  }
  updateBankAccounts(id: number, company: object) {
    return this.httpClient.put(environment.API_URL + '/api/bank-accounts/media-house-account/' + id, company);
  }
  deleteBankAccounts(id: number) {
    return this.httpClient.delete(environment.API_URL + '/api/bank-accounts/' + id);
  }


  getAMediaHouseAdvertsHistory(id: number, page: number) {
    return this.httpClient.get(environment.API_URL + '/api/adverts-info/media-house/' + id + `?size=7&page=${page}`);
  }

  getAMediaHouseAdvertsHistoryAndStatus(id: number, page: number, statuses: any[]) {
    
    return this.httpClient.get(environment.API_URL + '/api/adverts-info/media-house/' + id + `/list-status/${statuses.toString()}?size=7&page=${page}`);
  }

  searchMediaHouseAdverts(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/adverts-info/search/mediahouse/' + id + '/search/' + keyword);
  }
  searchAdvertiserAdverts(id: number, keyword: string) {
    return this.httpClient.get(environment.API_URL + '/api/adverts-info/search/advertiser/' + id + '/search/' + keyword);
  }
}
